import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  dateObj,
  idx,
  handleClick,
}) => {
  const handleDateClick = () => {
    handleClick(idx);
  }

  const buttonClasses = classNames({
    'clickable-link': true,
    'is-disabled': dateObj.disabled,
    'is-today': dateObj.today,
  });

  return (
    <span tabIndex={0} className={buttonClasses} onClick={handleDateClick}>
      <div className="button nav-button image is-1by1">
        <div className="nav-button-content has-ratio">
          <p>{dateObj.dayOfWeekStr}</p>
        </div>
      </div>
    </span>
  );
};

Button.propTypes = {
  dateObj: PropTypes.shape({
    disabled: PropTypes.bool,
    today: PropTypes.bool,
    year: PropTypes.number,
    month: PropTypes.string,
    day: PropTypes.string,
    monthStr: PropTypes.string,
    dayOfMonthStr: PropTypes.string,
    dayOfWeekStr: PropTypes.string,
  }).isRequired,
  idx: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Button;
