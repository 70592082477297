import React from 'react';
import PropTypes from 'prop-types';

const BottomBars = ({ displayText }) => {
  return (
    <section id="bottom-bars" className="section">
      <div className="bottom-bar pause-bar">
        { displayText ? <p>PAUSE</p> : null }
      </div>
      <div className="bottom-bar read-bar">
        { displayText ? <p>READ</p> : null }
      </div>
      <div className="bottom-bar reflect-bar">
        { displayText ? <p>REFLECT</p> : null }
      </div>
      <div className="bottom-bar plan-bar">
        { displayText ? <p>PLAN</p> : null }
      </div>
      <div className="bottom-bar go-bar">
        { displayText ? <p>GO</p> : null }
      </div>
    </section>
  );
};

BottomBars.propTypes = {
  displayText: PropTypes.bool,
};

BottomBars.defaultProps = {
  displayText: false,
};

export default BottomBars;
