import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  addDays,
  format,
  getDate,
  getMonth,
  getYear,
  isFuture,
  isToday,
  startOfWeek,
} from 'date-fns';
import { motion } from 'framer-motion';
import { HomeImageContext } from 'contextProviders/homeImage';
import Navbar from 'components/Navbar';
import DailyImage from 'components/DailyImage';
import DateButtons from 'components/DateButtons';
import BottomBars from 'components/BottomBars';
import LoadingOverlay from 'components/LoadingOverlay';
import homeContentLogo from 'media/home-logo.png';

const generateDateArray = () => {
  let dateArray = [];
  let startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  for (let i = 0; i < 5; i++) {
    const d = addDays(startDate, i);
    const disabled = isFuture(d);
    const today = isToday(d);
    const year = getYear(d);
    const month = `${getMonth(d) + 1}`.padStart(2, '0');
    const day = `${getDate(d)}`.padStart(2, '0');
    const monthStr = format(d, 'MMMM');
    const dayOfMonthStr = format(d, 'd');
    const dayOfWeekStr = format(d, 'EEE');
    dateArray.push({
      disabled,
      today,
      year,
      month,
      day,
      monthStr,
      dayOfMonthStr,
      dayOfWeekStr,
    });
  }
  return dateArray;
}

const DateSelector = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { defaultImage, currentImage } = useContext(HomeImageContext);

  useEffect(() => {
    const d = generateDateArray();
    setDates(d);
    setLoading(false);
  }, []);

  const handleDateClick = (idx) => {
    if (!dates[idx].disabled) {
      const newLiftDataId = `${dates[idx].year}-${dates[idx].month}-${dates[idx].day}`;
      history.push(`/lift/${newLiftDataId}${location.search}`);
    }
  }

  return (
    <motion.div
      id="app"
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Navbar
        returnLink={props.returnLink}
        returnLinkTitle={props.returnLinkTitle}
      />

      <DailyImage
        src={currentImage || defaultImage}
      />
      <DateButtons
        dateArr={dates}
        handleClick={handleDateClick}
      />
      <div className="animation-wrapper" style={{position: 'relative'}}>
        <section id="main-content" className="section">
          <div className="content primary">
            <h1 className="welcome-title">WELCOME!</h1>
            <div className="welcome-image">
              <figure className="image is-2by1">
                <img src={homeContentLogo} alt="Daily Lift" />
              </figure>
            </div>
            <p>This is your Daily Reminder. Remember to check back each day.</p>
          </div>
        </section>
      </div>
      <BottomBars displayText />
      <LoadingOverlay show={loading} />
    </motion.div>
  );
}

DateSelector.propTypes = {
  returnLink: PropTypes.string,
  returnLinkTitle: PropTypes.string,
}

export default DateSelector;