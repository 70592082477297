import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fontsource/roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import 'style/index.sass';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (Number(process.env.REACT_APP_REGISTER_SERVICE_WORKER) === 1) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}