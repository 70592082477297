import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion";
import marked from 'marked';
import { MdTouchApp } from 'react-icons/md';
import { ReactComponent as PauseIcon } from '../NavButtons/pause-icon.svg';
import { ReactComponent as ReadIcon } from '../NavButtons/read-icon.svg';
import { ReactComponent as ReflectIcon } from '../NavButtons/reflect-icon.svg';
import { ReactComponent as PlanIcon } from '../NavButtons/plan-icon.svg';
import { ReactComponent as GoIcon } from '../NavButtons/go-icon.svg';

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const PageContent = ({ content, variants, direction, paginate, pageName }) => {
  const [allowScroll, setAllowScroll] = useState(false)
  useEffect(() => {
    if (allowScroll) {
      const handleTouch = event => {
        event.stopPropagation();
      }
      document.documentElement.addEventListener('touchmove', handleTouch)
      return () => {
        document.documentElement.removeEventListener('touchmove', handleTouch)
      }
    }
  }, [allowScroll])

  let Icon = null;
  switch (pageName) {
    case 'pause':
      Icon = PauseIcon;
      break;
    case 'read':
      Icon = ReadIcon;
      break;
    case 'reflect':
      Icon = ReflectIcon;
      break;
    case 'plan':
      Icon = PlanIcon;
      break;
    case 'go':
      Icon = GoIcon;
      break;
    default:
  }


  return (
    <motion.section
      id="main-content"
      className={`section ${pageName}`}
      initial="initial"
      animate="enter"
      exit="exit"
      custom={direction}
      variants={variants}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 200 },
        opacity: { duration: 0.2 }
      }}
      dragDirectionLock={true}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      onDragStart={(e, info) => {
        setAllowScroll(Math.abs(info.delta.y) > Math.abs(info.delta.x));
      }}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x);

        if (swipe < -swipeConfidenceThreshold) {
          paginate(1);
        } else if (swipe > swipeConfidenceThreshold) {
          paginate(-1);
        }
      }}
    >
      {
        Icon ? (
          <div className="touch-page-header">
            <div className="icon-wrapper"><Icon /></div>
            <p className="page-title-wrapper">{pageName}</p>
          </div>
        ) : null
      }
      <div className="content primary" dangerouslySetInnerHTML={{__html: marked(content.md)}}></div>
      {
        pageName === 'home' ? (
          <div className="swipe-indicator"><MdTouchApp size={50} /><span>swipe</span></div>
        ) : null
      }
      {
        content.refMd && content.refMd.length > 0 ? (
          <React.Fragment>
            <hr className="reference-hr" />
            <div className="content reference" dangerouslySetInnerHTML={{__html: marked(content.refMd)}}></div>
          </React.Fragment>
        ) : null
      }
    </motion.section>
  );
};

PageContent.propTypes = {
  content: PropTypes.object.isRequired,
  variants: PropTypes.object.isRequired,
  direction: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default PageContent;