import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const DailyImage = ({ src }) => {

  const mediaData = useMemo(() => {
    const d = {
      src: `${process.env.PUBLIC_URL}/defaultLiftImage.jpg`,
      type: 'image',
    }

    if (src) {
      d.src = src;
      if (src.endsWith('.mp4')) {
        d.type = 'video';
      }
    }

    return d;
  }, [src]);

  return (
    <section id="daily-image" className="section">
      <figure className="image is-16by9">
        {
          mediaData.type === 'video' &&
          <video className="has-ratio" muted autoPlay loop playsInline preload="auto">
            <source src={mediaData.src} type="video/mp4" />
          </video>
        }
        {
          mediaData.type === 'image' &&
          <img src={mediaData.src} alt="" className="" />
        }
      </figure>
    </section>
  );
};

DailyImage.propTypes = {
  src: PropTypes.string.isRequired,
  currentLiftId: PropTypes.string,
};

DailyImage.defaultProps = {
  currentLiftId: null,
}

export default DailyImage;