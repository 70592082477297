import React, { useCallback, useEffect } from 'react';
import isTouchDevice from 'is-touch-device';
import { throttle } from 'lodash';
import { HashRouter as Router } from 'react-router-dom';
import Routes from 'Routes';
import { HomeImageProvider } from 'contextProviders/homeImage';

const App = () => {

  const setDocHeight = useCallback(throttle(() => {
    document.querySelector('html').setAttribute('style', `height:${window.innerHeight}px;`);
  }, 250), []);

  const detectTouch = useCallback(() => {
    if (isTouchDevice() && window.innerWidth < 1024) {
      document.body.classList.remove('is-mouse');
      document.body.classList.add('is-touch');
      window.addEventListener('resize', setDocHeight);
      window.addEventListener('orientationchange', setDocHeight);
      setDocHeight();
    } else {
      document.body.classList.remove('is-touch');
      document.body.classList.add('is-mouse');
    }
  }, [setDocHeight]);

  useEffect(detectTouch, []);

  return (
    <HomeImageProvider>
      <Router>
        <Routes />
      </Router>
    </HomeImageProvider>
  );
}

export default App;