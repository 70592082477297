import React from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { TiArrowBackOutline } from 'react-icons/ti';
import logoSrc from './logo.png';

const Navbar = props => {
  const location = useLocation();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/${location.search}`);
  }

  return (
    <div className={`top-logo${props.returnLink ? ' has-exit-link' : ''}`}>
      <span
        tabIndex={0}
        className={`clickable-link`}
        onClick={handleClick}
      >
        <img src={logoSrc} alt="" />
      </span>
      {
        props.returnLink && props.returnLink.length > 0 ? (
          <a
            href={props.returnLink}
            className="button is-primary exit-btn is-rounded"
          >
            <TiArrowBackOutline size={40} />
            {
              props.returnLinkTitle ? (
                <span className="has-text-weight-bold">
                  {props.returnLinkTitle}
                </span>
              ) : (
                <span className="has-text-weight-bold">
                  BACK TO <span className="is-italic is-size-6">alift</span>
                </span>
              )
            }
          </a>
        ) : null
      }
    </div>
  );
};

Navbar.propTypes = {
  returnLink: PropTypes.string,
  returnLinkTitle: PropTypes.string,
}

export default Navbar;