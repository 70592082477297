import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

const LoadingOverlay = ({ show }) => {
  return (
    <AnimatePresence>
      {
        show ? (
          <motion.div
            key='loading-overlay'
            className="loading-overlay"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, type: 'tween', ease: 'linear' }}
          >
            <div className="spinner">
              <div className="outer">
                <div className="inner tl"></div>
                <div className="inner tr"></div>
                <div className="inner br"></div>
                <div className="inner bl"></div>
              </div>
            </div>
          </motion.div>
        ) : null
      }
    </AnimatePresence>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
};

export default LoadingOverlay;