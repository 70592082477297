import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import Lift from 'Lift';
import DateSelector from 'DateSelector';
import NotFound from 'components/NotFound';
import { AnimatePresence } from 'framer-motion';

const Routes = () => {
  const location = useLocation();
  const history = useHistory();
  const [returnLink, setReturnLink] = useState(null);
  const [returnLinkTitle, setReturnLinkTitle] = useState(null);

  const assignReturnLink = useCallback(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    // returnLink
    if (query.returnTo) {
      setReturnLink(query.returnTo.length > 0 ? query.returnTo : null);
    } else if (Number(process.env.REACT_APP_DEMO_MODE) === 1 && process.env.REACT_APP_DEMO_EXIT_LINK_URL) {
      setReturnLink(process.env.REACT_APP_DEMO_EXIT_LINK_URL);
    } else {
      if (document.referrer && document.referrer.length > 0 && document.referrer.indexOf(process.env.PUBLIC_URL) !== 0) {
        setReturnLink(document.referrer);
      } else {
        setReturnLink(null);
      }
    }

    // returnLinkTitle
    if (query.returnToTitle && query.returnToTitle.length > 0) {
      setReturnLinkTitle(query.returnToTitle);
    } else if (Number(process.env.REACT_APP_DEMO_MODE) === 1 && process.env.REACT_APP_DEMO_EXIT_LINK_TITLE) {
      setReturnLinkTitle(process.env.REACT_APP_DEMO_EXIT_LINK_TITLE);
    } else {
      setReturnLinkTitle(null);
    }

  }, [location.search]);

  useEffect(assignReturnLink, [location.search]);

  useEffect(() => {
    if (Number(process.env.REACT_APP_DEMO_MODE) === 1) {
      history.replace('/demo');
    }
  }, [history]);

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Switch location={location} key={location.pathname === '/' ? 'home' : 'lift'}>
        <Route path="/" exact>
          <DateSelector
            returnLink={returnLink}
            returnLinkTitle={returnLinkTitle}
          />
        </Route>
        <Route path="/lift/:liftId">
          <Lift
            returnLink={returnLink}
            returnLinkTitle={returnLinkTitle}
          />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </AnimatePresence>
  );
}

export default Routes;