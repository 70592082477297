import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PauseIcon } from './pause-icon.svg';
import { ReactComponent as ReadIcon } from './read-icon.svg';
import { ReactComponent as ReflectIcon } from './reflect-icon.svg';
import { ReactComponent as PlanIcon } from './plan-icon.svg';
import { ReactComponent as GoIcon } from './go-icon.svg';

const Button = ({ type, currentLiftId, currentPath, handleLinkClick }) => {

  let Icon = null;
  let linkUrl = null;
  switch (type) {
    case 'pause':
      Icon = PauseIcon;
      linkUrl = `/lift/${currentLiftId}/pause`;
      break;
    case 'read':
      Icon = ReadIcon;
      linkUrl = `/lift/${currentLiftId}/read`;
      break;
    case 'reflect':
      Icon = ReflectIcon;
      linkUrl = `/lift/${currentLiftId}/reflect`;
      break;
    case 'plan':
      Icon = PlanIcon;
      linkUrl = `/lift/${currentLiftId}/plan`;
      break;
    case 'go':
      Icon = GoIcon;
      linkUrl = `/lift/${currentLiftId}/go`;
      break;
    default:
  }

  const handleClick = () => {
    handleLinkClick(linkUrl);
  }

  return (
    <span tabIndex={0} className={`clickable-link${currentPath === linkUrl ? ' active' : ''}`} onClick={handleClick}>
      <div className={`${type}-button nav-button image is-1by1`}>
        <div className="nav-button-content has-ratio">
          <Icon />
          <p>{type}</p>
        </div>
      </div>
    </span>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['pause', 'read', 'reflect', 'plan', 'go']),
  currentLiftId: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default Button;