import React from 'react';

const NotFound = props => {
  return (
    <div id="app" className="container">
      <section id="main-content" className="section">
        <div className="content">
          Error - Page Not Found
        </div>
      </section>
    </div>
  );
};

NotFound.propTypes = {

};

export default NotFound;