import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import axios from 'axios';
import {
  format,
  isAfter,
  set,
} from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from 'components/Navbar';
import DailyImage from 'components/DailyImage';
import NavButtons from 'components/NavButtons';
import PageContent from 'components/PageContent';
import BottomBars from 'components/BottomBars';
import LoadingOverlay from 'components/LoadingOverlay';
// import HomePage from 'pages/HomePage';

const createDateString = (dateStr) => {
  const vals = dateStr.split('-');
  const d = set(new Date(), { year: vals[0], month: parseInt(vals[1], 10) - 1, date: vals[2] });
  if (isAfter(d, new Date())) {
    return '__LOCKED_DL__';
  }
  return format(d, 'MMMM d, yyyy');
}

const transformResponse = (responseData) => {
  if (!responseData?.data?.[0]?.attributes) return null;
  const liftData = {
    meta: {
      title: '',
      description: '',
      backgroundImg: '',
      dateCreated: '',
    },
    pages: {
      home: {
        md: '# WELCOME!\n\nThis is your Daily Reminder. Remember to check back each day.',
      },
      pause: {
        md: '',
      },
      read: {
        md: '',
      },
      reflect: {
        md: '',
      },
      plan: {
        md: '',
      },
      go: {
        md: '',
      },
    },
  };
  const { attributes } = responseData.data[0];
  if (attributes?.date) liftData.meta.title = attributes.date;
  if (attributes?.description) liftData.meta.description = attributes.description;
  if (attributes?.background?.data?.attributes?.url) liftData.meta.backgroundImg = attributes.background.data.attributes.url;
  if (attributes?.createdAt) liftData.meta.dateCreated = attributes.createdAt;
  if (attributes?.pause_text || attributes?.pause_reference_text) {
    const pauseObj = {};
    if (attributes?.pause_text) pauseObj.md = attributes.pause_text;
    if (attributes?.pause_reference_text) pauseObj.refMd = attributes.pause_reference_text;
    liftData.pages.pause = pauseObj;
  }
  if (attributes?.read_text || attributes?.read_reference_text) {
    const readObj = {};
    if (attributes?.read_text) readObj.md = attributes.read_text;
    if (attributes?.read_reference_text) readObj.refMd = attributes.read_reference_text;
    liftData.pages.read = readObj;
  }
  if (attributes?.reflect_text || attributes?.reflect_reference_text) {
    const reflectObj = {};
    if (attributes?.reflect_text) reflectObj.md = attributes.reflect_text;
    if (attributes?.reflect_reference_text) reflectObj.refMd = attributes.reflect_reference_text;
    liftData.pages.reflect = reflectObj;
  }
  if (attributes?.plan_text || attributes?.plan_reference_text) {
    const planObj = {};
    if (attributes?.plan_text) planObj.md = attributes.plan_text;
    if (attributes?.plan_reference_text) planObj.refMd = attributes.plan_reference_text;
    liftData.pages.plan = planObj;
  }
  if (attributes?.go_text || attributes?.go_reference_text) {
    const goObj = {};
    if (attributes?.go_text) goObj.md = attributes.go_text;
    if (attributes?.go_reference_text) goObj.refMd = attributes.go_reference_text;
    liftData.pages.go = goObj;
  }
  return liftData;
}

const transitionVariants = {
  initial: (direction) => {
    return {
      position: 'absolute',
      zIndex: 0,
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }
  },
  enter: {
    position: 'relative',
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      position: 'absolute',
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0
    }
  }
};

const Lift = (props) => {
  const { liftId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [liftDate, setLiftDate] = useState(createDateString(liftId));
  const [liftData, setLiftData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [[routeIdx, direction], setRouteIdx] = useState([0, 0]);
  const [validRoutes, setValidRoutes] = useState([ `/lift/${liftId}/pause`, `/lift/${liftId}/read`, `/lift/${liftId}/reflect`, `/lift/${liftId}/plan`, `/lift/${liftId}/go` ]);
  // allow cancellation of the xhr request
  const getDataAbortController = useRef();

  const getLiftData = useCallback(() => {
    if (getDataAbortController.current) {
      getDataAbortController.current.abort();
    }

    if (!liftId) {
      setLiftDate('');
      setLiftData(null);
      setValidRoutes([]);
      setRouteIdx([0, 0]);
    } else {
      setLoading(true);

      getDataAbortController.current = new AbortController();
      const { signal } = getDataAbortController.current;
      axios.get(`${process.env.REACT_APP_LIFT_SOURCE}/daily-lifts?filters[date][$eqi]=${liftId}&populate[background][fields][0]=url&publicationState=live`, {
        signal,
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_LIFT_API_KEY}`,
        },
      })
        .then((res) => {
          const newLiftData = transformResponse(res.data);
          setLiftDate(createDateString(liftId));
          setLiftData(newLiftData);
          setValidRoutes([ `/lift/${liftId}/pause`, `/lift/${liftId}/read`, `/lift/${liftId}/reflect`, `/lift/${liftId}/plan`, `/lift/${liftId}/go` ]);
          history.replace(`/lift/${liftId}/pause${location.search}`);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status && err.response.status === 401) {
            return window.location.replace('https://alift.work');
          }
          setLiftDate('');
          setLiftData(null);
          setValidRoutes([]);
        })
        .finally(() => {
          getDataAbortController.current = null;
          setRouteIdx([0, 0]);
          setLoading(false);
        });
    }
    return () => {
      if (getDataAbortController.current) {
        getDataAbortController.current.abort();
      }
    }
  }, [liftId, history, location]);

  useEffect(getLiftData, [liftId]);

  const handleLinkClick = (newRoute) => {
    let foundLocIdx = validRoutes.indexOf(newRoute);
    if (foundLocIdx > -1 && foundLocIdx !== routeIdx) {
      setRouteIdx([foundLocIdx, foundLocIdx > routeIdx ? 1 : -1]);
      history.push(`${newRoute}${location.search}`);
    }
  }

  const paginate = (newDirection) => {
    // console.log('paginating', routeIdx, newDirection);
    if (routeIdx === 0 && newDirection === -1) return;
    if (routeIdx === validRoutes.length - 1 && newDirection === 1) return;
    const newRouteIdx = routeIdx + newDirection;
    const newRoute = validRoutes[newRouteIdx];
    if (!newRoute) return;
    setRouteIdx([newRouteIdx, newDirection]);
    history.push(`${newRoute}${location.search}`);
  }

  return (
    <motion.div
      id="app"
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Navbar
        returnLink={props.returnLink}
        returnLinkTitle={props.returnLinkTitle}
      />
      {
        !loading && liftDate === '__LOCKED_DL__' && (
          <section id="main-content" className="section">
            <p>This Daily Lift is locked. You cannot currently access it.</p>
          </section>
        )
      }
      {
        liftData && !loading && liftDate !== '__LOCKED_DL__' ? (
          <React.Fragment>
            <DailyImage
              src={liftData.meta.backgroundImg}
              currentLiftId={liftId}
            />
            <NavButtons
              currentLiftId={liftId}
              handleLinkClick={handleLinkClick}
              liftDate={liftDate}
            />
            <div className="animation-wrapper" style={{position: 'relative'}}>
              <AnimatePresence initial={false}>
                <Switch location={location} key={location.pathname}>
                  {/* <Route
                    exact
                    path={`/${liftId}`}
                  >
                    <HomePage
                      pageName="home"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route> */}
                  <Route
                    exact
                    path={`/lift/${liftId}/pause`}
                  >
                    <PageContent
                      content={liftData.pages.pause}
                      pageName="pause"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/lift/${liftId}/read`}
                  >
                    <PageContent
                      content={liftData.pages.read}
                      pageName="read"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/lift/${liftId}/reflect`}
                  >
                    <PageContent
                      content={liftData.pages.reflect}
                      pageName="reflect"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/lift/${liftId}/plan`}
                  >
                    <PageContent
                      content={liftData.pages.plan}
                      pageName="plan"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/lift/${liftId}/go`}
                  >
                    <PageContent
                      content={liftData.pages.go}
                      pageName="go"
                      variants={transitionVariants}
                      direction={direction}
                      paginate={paginate}
                    />
                  </Route>
                </Switch>
              </AnimatePresence>
            </div>
            <BottomBars displayText={location.pathname === `/lift/${liftId}`} />
          </React.Fragment>
        ) : (
          <section id="main-content" className="section">
            <p>This Daily Lift does not exist.</p>
          </section>
        )
      }
      <LoadingOverlay show={loading} />
    </motion.div>
  );
}

Lift.propTypes = {
  returnLink: PropTypes.string,
  returnLinkTitle: PropTypes.string,
}

export default Lift;