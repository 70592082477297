import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
  useHistory,
} from 'react-router-dom';
import DateDisplay from 'components/DateDisplay';
import Button from './Button';

const NavButtons = props => {
  const history = useHistory();
  const handleHomeClick = () => {
    history.push(`/${props.location.search}`);
  };
  return (
    <section id="nav-buttons" className="section">
      <DateDisplay>
        <span tabIndex={0} className="clickable-link" onClick={handleHomeClick}>
          {props.liftDate}
        </span>
      </DateDisplay>
      <Button type="pause" currentLiftId={props.currentLiftId} currentPath={props.location.pathname} handleLinkClick={props.handleLinkClick} />
      <Button type="read" currentLiftId={props.currentLiftId} currentPath={props.location.pathname} handleLinkClick={props.handleLinkClick} />
      <Button type="reflect" currentLiftId={props.currentLiftId} currentPath={props.location.pathname} handleLinkClick={props.handleLinkClick} />
      <Button type="plan" currentLiftId={props.currentLiftId} currentPath={props.location.pathname} handleLinkClick={props.handleLinkClick} />
      <Button type="go" currentLiftId={props.currentLiftId} currentPath={props.location.pathname} handleLinkClick={props.handleLinkClick} />
    </section>
  );
};

NavButtons.propTypes = {
  currentLiftId: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  liftDate: PropTypes.string,
};

NavButtons.defaultProps = {
  liftDate: '',
};

export default withRouter(NavButtons);