import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { HomeImageContext } from './HomeImageContext';
import LoadingOverlay from 'components/LoadingOverlay';

export const HomeImageProvider = ({ children }) => {
  const [defaultImage, setDefaultImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [fetchComplete, setFetchComplete] = useState([false, false]);
  // allow cancellation of the xhr request
  const getDefaultImageAbortController = useRef();
  const getCurrentImageAbortController = useRef();

  const fetchDefaultImage = useCallback(() => {
    if (getDefaultImageAbortController.current) {
      getDefaultImageAbortController.current.abort();
    }

    getDefaultImageAbortController.current = new AbortController();
    const { signal } = getDefaultImageAbortController.current;
    axios.get(`${process.env.REACT_APP_LIFT_SOURCE}/default-home-image?populate[background][fields][0]=url`, {
      signal,
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_LIFT_API_KEY}`,
      },
    })
      .then((res) => {
        setDefaultImage(res?.data.data?.attributes?.background?.data?.attributes?.url || `${process.env.PUBLIC_URL}/dateImg.jpg`);
      })
      .catch((err) => {
        console.log(err);
        setDefaultImage(`${process.env.PUBLIC_URL}/dateImg.jpg`);
      })
      .finally(() => {
        getDefaultImageAbortController.current = null;
        setFetchComplete((prev) => [true, prev[1]]);
      });

    return () => {
      if (getDefaultImageAbortController.current) {
        getDefaultImageAbortController.current.abort();
      }
    }
  }, []);

  const fetchCurrentImage = useCallback(() => {
    if (getCurrentImageAbortController.current) {
      getCurrentImageAbortController.current.abort();
    }

    const todaysDate = format(new Date(), 'yyyy-MM-dd');

    getCurrentImageAbortController.current = new AbortController();
    const { signal } = getCurrentImageAbortController.current;
    axios.get(`${process.env.REACT_APP_LIFT_SOURCE}/home-images?filters[start_date][$lte]=${todaysDate}&filters[end_date][$gte]=${todaysDate}&populate[background][fields][0]=url&publicationState=live`, {
      signal,
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_LIFT_API_KEY}`,
      },
    })
      .then((res) => {
        if (res?.data?.data && res.data.data.length > 0) {
          setCurrentImage(res.data.data[0]?.attributes?.background?.data?.attributes?.url);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getCurrentImageAbortController.current = null;
        setFetchComplete((prev) => [prev[0], true]);
      });

    return () => {
      if (getCurrentImageAbortController.current) {
        getCurrentImageAbortController.current.abort();
      }
    }
  }, []);

  useEffect(() => {
    fetchDefaultImage();
    fetchCurrentImage();
  }, [fetchDefaultImage, fetchCurrentImage]);

  return (
    <HomeImageContext.Provider
      value={{
        defaultImage,
        currentImage,
      }}
    >
      <>
        {
          fetchComplete[0] && fetchComplete[1] && (
            <>
              {children}
            </>
          )
        }
        <LoadingOverlay show={!fetchComplete[0] || !fetchComplete[1]} />
      </>
    </HomeImageContext.Provider>
  );
};