import React from 'react';

const DateDisplay = ({ children }) => (
  <div className="date-display-box">
    <p>
      {children}
    </p>
  </div>
);

export default DateDisplay;