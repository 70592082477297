import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import DateDisplay from 'components/DateDisplay';

const getDateStr = (dateArr) => {
  let dateStr = '';
  if (dateArr.length > 0) {
    const first = dateArr[0];
    const last = dateArr[dateArr.length - 1];
    if (first.year !== last.year) {
      dateStr = `${first.monthStr} ${first.dayOfMonthStr}, ${first.year} - ${last.monthStr} ${last.dayOfMonthStr}, ${last.year}`;
      return dateStr;
    }
    if (first.month !== last.month) {
      dateStr = `${first.monthStr} ${first.dayOfMonthStr} - ${last.monthStr} ${last.dayOfMonthStr}, ${last.year}`;
      return dateStr;
    }
    dateStr = `${first.monthStr} ${first.dayOfMonthStr} - ${last.dayOfMonthStr}, ${last.year}`;
  }
  return dateStr;
}

const DateButtons = ({
  dateArr,
  handleClick,
}) => {
  const dateStr = getDateStr(dateArr);
  return (
    <section id="date-buttons" className="section">
      <DateDisplay>
        {dateStr}
      </DateDisplay>
      {
        dateArr.map((dateObj, idx) => {
          return (
            <Button
              key={`${dateObj.month}-${dateObj.day}`}
              dateObj={dateObj}
              idx={idx}
              handleClick={handleClick}
            />
          );
        })
      }
    </section>
  );
};

DateButtons.propTypes = {
  dateArr: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
};

DateButtons.defaultProps = {
  dateArr: [],
}

export default DateButtons;